import { Component, OnInit } from '@angular/core';
import {LangService} from "../../lang/lang.service";
import {HttpClient} from "@angular/common/http";
import {ActivatedRoute} from "@angular/router";
import {environment} from "../../../environments/environment";

@Component({
  selector: 'app-project-details',
  templateUrl: './project-details.component.html',
  styleUrls: ['./project-details.component.css']
})
export class ProjectDetailsComponent implements OnInit {


  dataList:any;
  eventData:any;
  id:any;

  mediaUrl:any;
  constructor(private  langService:LangService,private http:HttpClient,private route:ActivatedRoute) {

    this.route.params.subscribe( params =>
      {
        if(params['id']){
          this.id =params['id'];
        }
      }
    );
    this.mediaUrl=environment.mediaUrl;
    this.LoadData();
    this.LoadEventData();
    this.mediaUrl=environment.mediaUrl;
  }


  public  GetWordText(key:any){
    return this.langService.GetWord(key);

  }

  ngOnInit(): void {
  }

  urlFunc(arabic: string,english:string) {
    if(localStorage.getItem('lang')=='ar'){
      return  arabic.split(' ').join('-');
    }else if(localStorage.getItem('lang')=='en'){
      return  english.split(' ').join('-');
    }else {
      return  english.split(' ').join('-');
    }

  }

  public   ViewLang(arabic:string,english:string){
    if(localStorage.getItem('lang')=='ar'){
      return  arabic;
    }else if(localStorage.getItem('lang')=='en'){
      return  english;
    }else {
      return  english;
    }
  }
  getData(){
    return this.http.get<any>(environment.serverUrl+`projects/`)
  }
  getEventData(){
    return this.http.get<any>(environment.serverUrl+`project/`+this.id)
  }
  LoadData(){
    this.getData().subscribe(data=>{
      console.log(data.result);
      this.dataList=data.result;

    },error => {
      console.log(error);
    });
  }

  LoadEventData(){
    this.getEventData().subscribe(data=>{
      console.log(data.result);
      this.eventData=data.result;

    },error => {
      console.log(error);
    });
  }


}
