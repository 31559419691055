import { Component, OnInit } from '@angular/core';
import {environment} from "../../../environments/environment";
import {LangService} from "../../lang/lang.service";
import {HttpClient} from "@angular/common/http";

@Component({
  selector: 'app-projects-list',
  templateUrl: './projects-list.component.html',
  styleUrls: ['./projects-list.component.css']
})
export class ProjectsListComponent implements OnInit {

  servicesList:any;

  mediaUrl:any;
  constructor(private  langService:LangService,private http:HttpClient) {

    this.LoadServices();
    this.mediaUrl=environment.mediaUrl;
  }

  public  GetWordText(key:any){
    return this.langService.GetWord(key);

  }

  ngOnInit(): void {
  }

  urlFunc(arabic: string,english:string) {
    if(localStorage.getItem('lang')=='ar'){
      return  arabic.split(' ').join('-');
    }else if(localStorage.getItem('lang')=='en'){
      return  english.split(' ').join('-');
    }else {
      return  english.split(' ').join('-');
    }

  }

  public   ViewLang(arabic:string,english:string){
    if(localStorage.getItem('lang')=='ar'){
      return  arabic;
    }else if(localStorage.getItem('lang')=='en'){
      return  english;
    }else {
      return  english;
    }
  }
  getServices(){
    return this.http.get<any>(environment.serverUrl+`projects/`)
  }
  LoadServices(){
    this.getServices().subscribe(data=>{
      console.log(data.result);
      this.servicesList=data.result;

    },error => {
      console.log(error);
    });
  }

}
