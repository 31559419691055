import { Component, OnInit } from '@angular/core';
import {LangService} from "../../lang/lang.service";
import {environment} from "../../../environments/environment";

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css']
})
export class HeaderComponent implements OnInit {

  constructor(private  langService:LangService) { }

  ngOnInit(): void {
  }
  public  GetWordText(key:any){
    return this.langService.GetWord(key);

  }

  setWebLang(){
    if(localStorage.getItem('lang')=='ar'){
      localStorage.setItem('lang','en');
    }else if(localStorage.getItem('lang')=='en'){
      localStorage.setItem('lang','ar');
    }else {
      localStorage.setItem('lang','ar');
    }

   }
  changeWebLang(lang:string){
    localStorage.setItem('lang',lang);

  }

}
