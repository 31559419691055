
<!--hero section start-->
<!--<tbody *ngFor="let table of tables$ | async;let i=index">-->

<section  class="banner p-0 position-relative fullscreen-banner text-center">
  <div class="banner-slider owl-carousel no-pb" data-dots="false" data-nav="true">
    <div class="item slider_item" [attr.style]="'background-image: url('+mediaUrl+photo1.toString()+')'" data-overlay="7">
      <div class="align-center">
        <div class="container">
          <div class="row">
            <div class="col-lg-10 col-md-12 mx-auto">
              <div class="box-shadow p-sm-5 p-2 banner-1 position-relative" data-bg-color="rgba(255,255,255,0.030)">
                <h5 class="text-white mb-3 letter-space-3 animated6">{{ViewLang(services[0]['text1_ar'],services[0]['text1_en'])}}</h5>
                <h1 class="text-white mb-3 animated8">  {{ViewLang(services[0]['text2_ar'],services[0]['text2_en'])}}</h1>
                <p class="lead text-white animated5 mb-3">{{ViewLang(services[0]['text3_ar'],services[0]['text3_en'])}}</p>
                <a class="btn btn-theme btn-radius animated7" href="#">{{GetWordText('more_details')}}</a>
                <a class="btn btn-border btn-radius animated6" routerLink="contact-us">{{GetWordText('contact_us')}}</a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="item slider_item" [attr.style]="'background-image: url('+mediaUrl+photo1.toString()+')'" data-overlay="7">
      <div class="align-center">
        <div class="container">
          <div class="row">
            <div class="col-lg-10 col-md-12 mx-auto">
              <div class="box-shadow p-sm-5 p-2 banner-1 position-relative" data-bg-color="rgba(255,255,255,0.030)">
                <h5 class="text-white mb-3 letter-space-3 animated6">{{ViewLang(services[1]['text1_ar'],services[1]['text1_en'])}}</h5>
                <h1 class="text-white mb-3 animated8">  {{ViewLang(services[1]['text2_ar'],services[1]['text2_en'])}}</h1>
                <p class="lead text-white animated5 mb-3">{{ViewLang(services[1]['text3_ar'],services[1]['text3_en'])}}</p>
                <a class="btn btn-theme btn-radius animated7" href="#">{{GetWordText('more_details')}}</a>
                <a class="btn btn-border btn-radius animated6" routerLink="contact-us">{{GetWordText('contact_us')}}</a>

              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="item slider_item" [attr.style]="'background-image: url('+mediaUrl+photo1.toString()+')'" data-overlay="7">
      <div class="align-center">
        <div class="container">
          <div class="row">
            <div class="col-lg-10 col-md-12 mx-auto">
              <div class="box-shadow p-sm-5 p-2 banner-1 position-relative" data-bg-color="rgba(255,255,255,0.030)">
                <h5 class="text-white mb-3 letter-space-3 animated6">{{ViewLang(services[2]['text1_ar'],services[2]['text1_en'])}}</h5>
                <h1 class="text-white mb-3 animated8">  {{ViewLang(services[2]['text2_ar'],services[2]['text2_en'])}}</h1>
                <p class="lead text-white animated5 mb-3">{{ViewLang(services[2]['text3_ar'],services[2]['text3_en'])}}</p>
                <a class="btn btn-theme btn-radius animated7" href="#">{{GetWordText('more_details')}}</a>
                <a class="btn btn-border btn-radius animated6" routerLink="contact-us">{{GetWordText('contact_us')}}</a>

              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>


