import { Component } from '@angular/core';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent {
  title = 'kayanweb';
  ngOnInit() {
    if(localStorage.getItem('lang')==="ar"){
      document.getElementsByTagName("html")[0].setAttribute("lang", "ar");

    }else{
      document.getElementsByTagName("html")[0].setAttribute("lang", "en");

    }
  }

}
