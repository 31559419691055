import { Component, OnInit } from '@angular/core';
import {ActivatedRoute} from "@angular/router";
import {LangService} from "../../lang/lang.service";
import {HttpClient} from "@angular/common/http";
import {environment} from "../../../environments/environment";

@Component({
  selector: 'app-services-page',
  templateUrl: './services-page.component.html',
  styleUrls: ['./services-page.component.css']
})
export class ServicesPageComponent implements OnInit {
  id:any;
  mediaUrl:any;
  servicesData:any;
  allServices:any;
  constructor( private  route:ActivatedRoute,private  langService:LangService,private http:HttpClient) {

    this.route.params.subscribe( params =>
      {
        if(params['id']){
          this.id =params['id'];
        }
      }
    );
    this.mediaUrl=environment.mediaUrl;
  this.LoadServices();
  this.LoadAllServices();

  }

  public  GetWordText(key:any){
    return this.langService.GetWord(key);

  }

  ngOnInit(): void {
  }

  urlFunc(arabic: string,english:string) {
    if(localStorage.getItem('lang')=='ar'){
      return  arabic.split(' ').join('-');
    }else if(localStorage.getItem('lang')=='en'){
      return  english.split(' ').join('-');
    }else {
      return  english.split(' ').join('-');
    }

  }

  public   ViewLang(arabic:string,english:string){
    if(localStorage.getItem('lang')=='ar'){
      return  arabic;
    }else if(localStorage.getItem('lang')=='en'){
      return  english;
    }else {
      return  english;
    }
  }
  getServices(){
    return this.http.get<any>(environment.serverUrl+`services/`+this.id)
  }  getAllServices(){
    return this.http.get<any>(environment.serverUrl+`services`)
  }
  LoadServices(){
    this.getServices().subscribe(data=>{
      console.log(data.result);
      this.servicesData=data.result;

    },error => {
      console.log(error);
    });
  }
  LoadAllServices(){
    this.getAllServices().subscribe(data=>{
      console.log(data.result);
      this.allServices=data.result;

    },error => {
      console.log(error);
    });
  }
}
