<app-header></app-header>
<!--page title start-->
<app-page-route title="news" ></app-page-route>
<section>
  <div class="container">
    <div class="row text-center">
      <div class="col-lg-8 col-md-10 mx-auto">
        <div class="section-title">
          <h2 class="title">{{GetWordText('Our')}} <span> {{GetWordText('news')}}</span></h2>
          <p class="mb-0"> {{GetWordText('latestNewsDescription')}} </p>
        </div>
      </div>
    </div>
    <div class="row">
      <div *ngFor="let row of dataList" class="col-lg-4 col-md-12 mt-5">
        <div class="post">
          <div class="post-image">
            <img src="{{mediaUrl+row['photo']}}" alt="">
            <div class="post-date">{{row['createdAt']}}</div>
          </div>
          <div class="post-desc">
            <div class="post-title">
              <h5><a routerLink="/news/{{row['id']}}/{{urlFunc(row['title_ar'],row['title_en'])}}">{{ViewLang(row['title_ar'],row['title_en'])}}</a></h5>
            </div>
            <p>
              {{ (ViewLang(row['content_ar'],row['content_en']).length>100)? (ViewLang(row['content_ar'],row['content_en']) | slice:0:100)+'..':(ViewLang(row['content_ar'],row['content_en'])) }}

            </p>
          </div>
          <div class="post-bottom">
            <a class="post-btn" routerLink="/news/{{row['id']}}/{{urlFunc(row['title_ar'],row['title_en'])}}">{{GetWordText('read_more')}}<i class="ms-2 fas fa-long-arrow-alt-right"></i></a>
          </div>
        </div>
      </div>

    </div>
  </div>
</section>

<app-footer></app-footer>
