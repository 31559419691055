<app-header></app-header>
<!--body content start-->
<app-slider></app-slider>
<div class="page-content">

  <!--social-resposability us start-->

  <section>
    <div class="container">
      <div class="row text-center">
        <div class="col-lg-8 col-md-10 mx-auto">
          <div class="section-title">
            <h2 class="title">{{GetWordText('about_our')}}<span>{{GetWordText('Company')}}</span></h2>
            <p class="mb-0"> </p>
          </div>
        </div>
      </div>
      <div class="row align-items-center">
        <div class="col-lg-6 col-md-12">
          <div class="row">
            <div class="col-md-6 pe-2">
              <div class="about-img mb-3">
                <img class="img-fluid" src="{{photo_1}}" alt="">
              </div>
              <div class="about-img">
                <img class="img-fluid" src="{{photo_2}}" alt="">
              </div>
            </div>
            <div class="col-md-6 mt-4 ps-2">
              <div class="about-img mb-3">
                <img class="img-fluid" src="{{photo_3}}" alt="">
              </div>
              <div class="about-img">
                <img class="img-fluid" src="{{photo_4}}" alt="">
              </div>
            </div>
          </div>
        </div>
        <div class="col-lg-6 col-md-12 box-shadow white-bg p-md-4 p-3 mt-5 mt-lg-0">
          <h5>ِ{{about_title}}</h5>
          <p class="line-h-3">
             {{about_content}}
          </p>
<!--           <div class="row text-black mt-4">-->
<!--            <div class="col-sm-6">-->
<!--              <ul class="list-unstyled">-->
<!--                <li class="mb-2">- Sharing risk and rewards </li>-->
<!--                <li class="mb-2">- Qualified Professionals </li>-->
<!--                <li>- Flexibility </li>-->
<!--              </ul>-->
<!--            </div>-->
<!--            <div class="col-sm-6 mt-3 mt-sm-0">-->
<!--              <ul class="list-unstyled">-->
<!--                <li class="mb-2">- Determination to excel</li>-->
<!--                <li class="mb-2">- Eagerness to grow</li>-->
<!--                <li>- Aim to exceed,excel and go pass beyond our clients expectations </li>-->
<!--              </ul>-->
<!--            </div>-->
<!--          </div>-->
         </div>
      </div>
    </div>
  </section>

  <!--social-resposability us end-->


  <!--services start-->

  <section class="feuture-main text-center">
    <div class="container">
      <div class="row">
        <div class="col-lg-8 col-md-10 mx-auto">
          <div class="section-title">
            <h2 class="title">{{GetWordText('Our')}} <span> {{GetWordText('services')}}</span></h2>
            <p class="mb-0 text-white"> {{GetWordText('services_description')}} </p>
          </div>
        </div>
      </div>
      <div class="row">

        <div *ngFor="let row of services"  class="col-lg-4 col-md-6">
          <div class="featured-item text-center">
            <div class="featured-icon"> <i class="{{row['icon']}}"></i>
            </div>
            <div class="featured-title text-uppercase">
              <h5>{{ViewLang(row['name_ar'],row['name_en'])}}</h5>
            </div>
            <div class="featured-desc">
              <p class="mb-3">
                {{ (ViewLang(row['content_ar'],row['content_en']).length>100)? (ViewLang(row['content_ar'],row['content_en']) | slice:0:100)+'..':(ViewLang(row['content_ar'],row['content_en'])) }}
              </p> <a routerLink="/services/{{row['id']}}/{{urlFunc(row['name_ar'],row['name_en'])}}">{{GetWordText('more_details')}} <i class="fas fa-arrow-right"></i></a>
            </div>
          </div>
        </div>

<!--        <div *ngFor="let row of services"  class="col-lg-4 col-md-6 mb-4" >-->
<!--          <div class="service-box">-->
<!--            <div class="service-flip theme-bg" >-->
<!--              <div class="service-flip-images service-flip-front" style="{{'background-image: url('+mediaUrl+row['photo']+')'}}" >-->
<!--                <div class="service-flip-title">-->
<!--                  <h5>{{ViewLang(row['name_ar'],row['name_en'])}}</h5>-->
<!--                </div>-->
<!--                <div class="flip-icon"> <i class="{{row['icon']}}"></i>-->
<!--                </div>-->
<!--              </div>-->
<!--              <div class="service-flip-desc service-flip-back">-->
<!--                <div class="service-flip-text"> <i class="{{row['icon']}}"></i>-->
<!--                  <p>-->
<!--                    {{ (ViewLang(row['content_ar'],row['content_en']).length>100)? (ViewLang(row['content_ar'],row['content_en']) | slice:0:100)+'..':(ViewLang(row['content_ar'],row['content_en'])) }}-->
<!--                  </p>-->
<!--                  <a class="btn btn-info btn-border" >{{GetWordText('more_details')}} <i class="fas fa-arrow-right"></i></a>-->

<!--                </div>-->
<!--              </div>-->
<!--            </div>-->
<!--          </div>-->
<!--        </div>-->

      </div>


     </div>
  </section>

  <!--services end-->


  <!--feauture start-->

  <section class="grey-bg pattern feuture-bottom white-overlay" data-bg-img="assets/images/pattern/01.png" data-overlay="3">
    <div class="container">
      <div class="row g-0 align-items-center">
        <div class="col-lg-6 col-md-6 pe-lg-5 md-px-2 text-center">
          <div class="section-title mb-md-0">
            <h2 class="title">{{GetWordText('Why')}} <span> {{GetWordText('Choose Us')}}</span></h2>
            <p class="mb-0">  {{GetWordText('why_choose_us_description')}}</p>
          </div>
        </div>
        <div class="col-md-6">
          <div class="featured-item bottom-icon">
            <div class="featured-title text-uppercase">
              <h5>{{ViewLang(competencies[0]['name_ar'],competencies[0]['name_en'])}}</h5>
            </div>
            <div class="featured-desc">
              <p>
                {{ViewLang(competencies[0]['content_ar'],competencies[0]['content_en'])}}
              </p>
            </div>
            <div class="featured-icon"> <i class="{{competencies[0]['icon']}}"></i>
            </div> <span><i class="{{competencies[0]['icon']}}"></i></span>
          </div>
        </div>
      </div>
      <div class="row g-0">
           <div  *ngFor="let row of competencies_for "   class="col-lg-3 col-md-6">
            <div   class="featured-item bottom-icon">
              <div class="featured-title text-uppercase">
                <h5>{{ViewLang(row['name_ar'],row['name_en'])}}</h5>
              </div>
              <div class="featured-desc">
                <p>  {{ViewLang(row['content_ar'],row['content_en'])}} </p>
              </div>
              <div class="featured-icon"> <i class="{{row['icon']}}"></i>
              </div> <span><i class="{{row['icon']}}"></i></span>
            </div>
          </div>



      </div>
    </div>
  </section>

  <!--feauture end-->



  <!--counter start-->

  <section class="grey-bg">
    <div class="container">
      <div class="row">

        <div *ngFor="let row of home_counter;"  class="col-lg-3 col-sm-6">
          <div class="counter"> <i class="{{row['icon']}}"></i>
            <span class="count-number"     [attr.data-to]="row['counter']"  data-speed="500">{{row['counter']}}</span>
             <label>{{ViewLang(row['name_ar'],row['name_en'])}}</label>
          </div>
        </div>

      </div>
    </div>
  </section>


  <!--counter end-->


  <!--multi section start-->

  <section>
    <div class="container">
      <div class="row align-items-center">
        <div class="col-lg-6 col-md-12">
          <div class="owl-carousel no-pb" data-dots="false" data-items="3" data-margin="30" data-autoplay="true">
            <div class="item">
              <img class="img-fluid" src="{{mediaUrl+suppliers_logos[0]['logo']}}" alt="">
            </div>
            <div class="item">
              <img class="img-fluid" src="{{mediaUrl+suppliers_logos[1]['logo']}}" alt="">
            </div>
            <div class="item">
              <img class="img-fluid" src="{{mediaUrl+suppliers_logos[2]['logo']}}" alt="">
            </div>
            <div class="item">
              <img class="img-fluid" src="{{mediaUrl+suppliers_logos[3]['logo']}}" alt="">
            </div>
          </div>
          <div class="row mt-5">
            <div class="col-sm-12">
              <div class="tab">
                <!-- Nav tabs -->
                <nav>
                  <div class="nav nav-tabs" id="nav-tab" role="tablist">
                    <button class="nav-link active" id="tab-1" data-bs-toggle="tab" data-bs-target="#tab1-1" type="button" role="tab" aria-controls="tab1-1" aria-selected="true">{{GetWordText('our_mission')}}</button>
                    <button class="nav-link" id="tab-2" data-bs-toggle="tab" data-bs-target="#tab2-1" type="button" role="tab" aria-controls="tab2-1" aria-selected="false">{{GetWordText('our_vision')}}</button>
                  </div>
                </nav>
                <div class="tab-content" id="nav-tabContent">
                  <div class="tab-pane fade show active" id="tab1-1" role="tabpanel" aria-labelledby="tab-1">
                    <p class="mb-0 lead">
                    {{ViewLang(home_content[0]['content_ar'],home_content[0]['content_en'])}}
                    </p>
                  </div>
                  <div class="tab-pane fade" id="tab2-1" role="tabpanel" aria-labelledby="tab-2">
                    <p class="mb-0 lead">

                      {{ViewLang(home_content[1]['content_ar'],home_content[1]['content_en'])}}

                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-lg-6 col-md-12 mt-5 mt-lg-0">
          <div class="accordion" id="accordion">
            <div class="accordion-item">
              <h2 class="accordion-header" id="headingOne">
                <button class="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
                  {{ViewLang(home_content[2]['content_ar'],home_content[2]['content_en'])}}


                </button>
              </h2>
              <div id="collapseOne" class="accordion-collapse collapse show" aria-labelledby="headingOne" data-bs-parent="#accordion">
                <div class="accordion-body">
                  {{ViewLang(home_content[3]['content_ar'],home_content[3]['content_en'])}}


                </div>
              </div>
            </div>
            <div class="accordion-item">
              <h2 class="accordion-header" id="headingTwo">
                <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
                  {{ViewLang(home_content[4]['content_ar'],home_content[4]['content_en'])}}


                </button>
              </h2>
              <div id="collapseTwo" class="accordion-collapse collapse" aria-labelledby="headingTwo" data-bs-parent="#accordion">
                <div class="accordion-body">

                  {{ViewLang(home_content[5]['content_ar'],home_content[5]['content_en'])}}

                </div>
              </div>
            </div>
            <div class="accordion-item">
              <h2 class="accordion-header" id="headingThree">
                <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseThree" aria-expanded="false" aria-controls="collapseThree">

                  {{ViewLang(home_content[6]['content_ar'],home_content[6]['content_en'])}}



                </button>
              </h2>
              <div id="collapseThree" class="accordion-collapse collapse" aria-labelledby="headingThree" data-bs-parent="#accordion">
                <div class="accordion-body">

                  {{ViewLang(home_content[7]['content_ar'],home_content[7]['content_en'])}}

                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>

  <!--multi section end-->




  <!--subscribe start-->

  <section class="grey-bg py-5">
    <div class="container">
      <div class="row align-items-center">
        <div class="col-md-8 col-sm-12">
          <h3 class="text-black">{{GetWordText('call_2_action_part1')}} <span class="text-theme font-weight-bold"> {{GetWordText('call_2_action_part2')}}{{ViewLang('؟','?')}}</span></h3>
        </div>
        <div class="col-md-4 col-sm-12 text-md-end mt-3 mt-md-0"> <a routerLink="contact-us" class="btn btn-theme"><span>{{GetWordText('call_2_action_btn_text')}}</span></a>
        </div>
      </div>
    </div>
  </section>

  <!--subscribe end-->


  <!--blog start-->

  <section>
    <div class="container">
      <div class="row text-center">
        <div class="col-lg-8 col-md-10 mx-auto">
          <div class="section-title">
            <h2 class="title">{{GetWordText('latest')}} <span> {{GetWordText('blogs')}}</span></h2>
            <p class="mb-0"> {{GetWordText('latestBlogsDescription')}} </p>
          </div>
        </div>
      </div>
      <div class="row">
        <div *ngFor="let row of blogs" class="col-lg-4 col-md-12 mt-5">
          <div class="post">
            <div class="post-image">
              <img src="{{mediaUrl+row['photo']}}" alt="">
              <div class="post-date">{{row['createdAt']}}</div>
            </div>
            <div class="post-desc">
              <div class="post-title">
                <h5><a routerLink="/blog/{{row['id']}}/{{urlFunc(row['title_ar'],row['title_en'])}}">{{ViewLang(row['title_ar'],row['title_en'])}}</a></h5>
              </div>
              <p>
                {{ (ViewLang(row['content_ar'],row['content_en']).length>100)? (ViewLang(row['content_ar'],row['content_en']) | slice:0:100)+'..':(ViewLang(row['content_ar'],row['content_en'])) }}

                 </p>
            </div>
            <div class="post-bottom">
             <a class="post-btn" routerLink="/blog/{{row['id']}}/{{urlFunc(row['title_ar'],row['title_en'])}}">{{GetWordText('read_more')}}<i class="ms-2 fas fa-long-arrow-alt-right"></i></a>
            </div>
          </div>
        </div>

      </div>
    </div>
  </section>

  <!--blog end-->


</div>
<!--body content end-->
<app-footer></app-footer>
