<app-header></app-header>
<!--page title start-->
<app-page-title-route title="{{ViewLang(servicesData[0]['name_ar'],servicesData[0]['name_en'])}}" span_title=""></app-page-title-route>

<!--service details start-->

<section>
  <div class="container">
    <div class="row">
      <div class="col-lg-8 col-md-12">
        <div class="service-images">
          <img class="img-fluid w-100" src="{{mediaUrl+servicesData[0]['photo']}}" alt="">
        </div>
        <div class="service-details mt-4">
          <h2 class="title"> {{ViewLang(servicesData[0]['name_ar'],servicesData[0]['name_en'])}}</h2>
          <p class="lead">  {{ViewLang(servicesData[0]['content_ar'],servicesData[0]['content_en'])}}</p>
           </div>
      </div>
      <div class="col-lg-4 col-md-12 sidebar mt-5 mt-lg-0">
        <div class="widget">
          <div class="widget-contact p-sm-5 p-3 text-white" data-bg-img="assets/images/bg/01.jpg" data-overlay="8" >
            <h4 class="text-white text-capitalize">{{GetWordText('other_services')}}</h4>
           </div>
        </div>
        <div class="widget mb-5">
          <div class="sidebar-links">
            <ul class="list-unstyled">
              <li *ngFor="let row of allServices"><a routerLink="services/{{row['id']}}/{{urlFunc(row['name_ar'],row['name_en'])}}"><i class="fas fa-angle-right"></i> {{ViewLang(row['name_ar'],row['name_en'])}} </a>
              </li>


            </ul>
          </div>
        </div>
        <div class="widget mb-5"> <a target="_blank" href="kayan-ltd-profile.pdf" class="brochure"><i class="fas fa-file-pdf"></i> {{GetWordText('Download Brochure')}}<span>KAYAN-profile.pdf</span></a>
        </div>
        <div class="widget">
          <div class="widget-contact p-sm-5 p-3 text-white" data-bg-img="assets/images/bg/01.jpg" data-overlay="8">
            <h4 class="text-white text-capitalize">{{GetWordText('contact_us_help?')}}</h4>
            <p>{{GetWordText('contact_help_description')}}</p> <a class="btn btn-theme btn-sm" routerLink="contact-us"><span>{{GetWordText('Contact Us')}}</span></a>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>

<!--service details end-->
<app-footer></app-footer>
