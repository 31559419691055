<!--header start-->

<header id="site-header" class="header">
  <div class="top-bar d-sm-block d-none">
    <div class="container" style="direction: ltr !important;">
      <div class="row align-items-center">
        <div class="col-md-4">
          <div class="topbar-link d-md-block d-none">
            <ul class="list-inline">
              <li class="list-inline-item"><a href="mailto:{{GetWordText('email_text')}}"><i class="far red_color fa-envelope-open"></i>{{GetWordText('email_text')}}</a>
              </li>
              <li class="list-inline-item">
                <a href="tel:+{{GetWordText('phone_text')}}"> <i class="fas red_color fa-mobile-alt"></i>+{{GetWordText('phone_text')}}</a>
              </li>
            </ul>
          </div>
        </div>
        <div class="col-md-4 col-sm-6 text-center">
          <div class="search-box">
          <img   src="../../../assets/images/saudi-vision-2030.png">
          </div>
        </div>
        <div class="col-md-4 col-sm-6 text-end">
          <div class="social-icons social-hover top-social-list">
            <ul class="list-inline">
              <li class="social-facebook top_social_icon"><a href="{{GetWordText('facebook_url')}}"><i class="fab fa-facebook-f"></i></a>
              </li>
              <li class="social-gplus top_social_icon"><a href="{{GetWordText('google_url')}}"><i class="fab fa-google-plus-g"></i></a>
              </li>
              <li class="social-twitter top_social_icon"><a href="{{GetWordText('twitter_url')}}"><i class="fab fa-twitter"></i></a>
              </li>
              <li class="social-linkedin top_social_icon"><a href="{{GetWordText('linkedin_url')}}"><i class="fab fa-linkedin-in"></i></a>
              </li>
              <li>
               <a class="nav-link lang_link" (click)="changeWebLang('ar')" href="/">  {{GetWordText('ar')}}    </a> <a style="padding: 0; width: 15px;">|</a>
               <a class="nav-link lang_link" (click)="changeWebLang('en')" href="/">  {{GetWordText('en')}}    </a>

              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div id="header-wrap">
    <div class="container">
      <div class="row">
        <div class="col">
          <!-- Navbar -->
          <nav class="navbar navbar-expand-lg">
            <a class="navbar-brand logo" routerLink="/">
              <img id="logo-img" class="img-fluid" src="../../../assets/images/kayan-logo.png" alt="">
            </a>
            <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarNavDropdown" aria-expanded="false" aria-label="Toggle navigation"> <span class="navbar-toggler-icon"></span>
            </button>
            <div class="collapse navbar-collapse" id="navbarNavDropdown">
              <!-- Left nav -->
              <ul class="nav navbar-nav ms-auto">
                <li class="nav-item dropdown"> <a class="nav-link" routerLink="/">{{GetWordText('home')}}</a></li>
                 <li class="nav-item dropdown"> <a class="nav-link  dropdown-toggle" routerLink="#" data-bs-toggle="dropdown">{{GetWordText('About_us')}}</a>
                  <ul class="dropdown-menu">
                    <li><a routerLink="/about-us-history">{{GetWordText('our_History')}}</a></li>
                    <li><a routerLink="/about-us-our-vision">{{GetWordText('our_vision')}}</a></li>
                    <li><a routerLink="/about-us-our-mission">{{GetWordText('our_mission')}}</a></li>
                    <li><a routerLink="/social-responsibility">{{GetWordText('social-responsibility')}}</a></li>
                    <li><a routerLink="/values-goals">{{GetWordText('values-goals')}}</a></li>
                    <li><a routerLink="/why-choose-us">{{GetWordText('Why_choose_us?')}}</a></li>
                   </ul>
                </li>

                <li class="nav-item dropdown"> <a class="nav-link" routerLink="/services"> {{GetWordText('services')}} </a></li>
                <li class="nav-item dropdown"> <a class="nav-link" routerLink="/features"> {{GetWordText('Features')}} </a></li>
                <li class="nav-item dropdown"> <a class="nav-link" routerLink="/our-projects"> {{GetWordText('our_projects')}} </a></li>
                <li class="nav-item dropdown"> <a class="nav-link" routerLink="/news"> {{GetWordText('news')}}   </a></li>
                <li class="nav-item dropdown"> <a class="nav-link" routerLink="/events"> {{GetWordText('events')}}   </a></li>
                <li class="nav-item dropdown"> <a class="nav-link" routerLink="/blog"> {{GetWordText('blog')}}    </a></li>
                <li class="nav-item dropdown"> <a class="nav-link" routerLink="/jobs"> {{GetWordText('Jobs')}}   </a></li>
                <li class="nav-item dropdown"> <a class="nav-link" routerLink="/supplier-join"> {{GetWordText('suppliers_join')}}   </a></li>
                <li class="nav-item dropdown"> <a class="nav-link contact_nav" routerLink="/contact-us"> {{GetWordText('contact_us')}}  </a></li>

              </ul>
            </div>
          </nav>
        </div>
      </div>
    </div>
  </div>
</header>

<!--header end-->
