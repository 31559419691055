<app-header></app-header>
<!--page title start-->
<app-page-route title="Our" span_title="projects" ></app-page-route>
<section>
  <div class="container">
    <div class="row text-center">
      <div class="col-lg-8 col-md-10 mx-auto">
        <div class="section-title">
          <h2 class="title">{{GetWordText('Our')}} <span> {{GetWordText('projects')}}</span></h2>
          <p class="mb-0"> {{GetWordText('latestProjectsDescription')}} </p>
        </div>
      </div>
    </div>
    <div class="row">
      <div  *ngFor="let row of servicesList" class="col-lg-6 mt-5 mt-lg-0">
        <div    class="post style-2">
          <div class="row g-0">
            <div class="col-md-6">
              <div class="post-image">
                <img class="h-100" src="{{mediaUrl+row['photo']}}" alt="">
                <div class="post-date">{{row['createdAt']}}</div>
              </div>
            </div>
            <div class="col-md-6 d-flex align-items-center white-bg">
              <div>
                <div class="post-desc">

                  <div class="post-title">
                    <h5><a routerLink="/project/{{row['id']}}/{{urlFunc(row['title_ar'],row['title_en'])}}">{{ViewLang(row['title_ar'],row['title_en'])}}</a></h5>
                  </div>

                  <p>
                    {{ (ViewLang(row['content_ar'],row['content_en']).length>100)? (ViewLang(row['content_ar'],row['content_en']) | slice:0:100)+'..':(ViewLang(row['content_ar'],row['content_en'])) }}

                  </p>
                  <div class="post-bottom">
                    <a class="post-btn" routerLink="/project/{{row['id']}}/{{urlFunc(row['title_ar'],row['title_en'])}}">{{GetWordText('project_details')}}<i class="ms-2 fas fa-long-arrow-alt-right"></i></a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>



    </div>
  </div>
</section>

<app-footer></app-footer>
