import {Component, Input, OnInit} from '@angular/core';
import {LangService} from "../../lang/lang.service";
declare  function  databgcolor():any;
@Component({
  selector: 'app-page-title-route',
  templateUrl: './page-route-title.component.html',
  styleUrls: ['./page-route-title.component.css']
})
export class PageRouteTitleComponent implements OnInit {
  @Input() title: any;
  @Input() span_title: any;
  constructor(private  langService:LangService) { }

  ngOnInit(): void {
  }
  public  GetWordText(key:any){
    return this.langService.GetWord(key);

  }
}
