
<!--footer start-->

<footer class="footer dark-bg position-relative" data-bg-img="assets/images/bg/09.png" style="background-size: contain; background-repeat: no-repeat;">
  <div class="footer-contact">
    <div class="container">
      <div class="row">
        <div class="col">
          <div class="theme-bg">
            <div class="row g-0">
              <div class="col-md-4 mb-5 mb-md-0">
                <div class="px-5 pb-5 text-center footer-media"> <i class="flaticon-paper-plane"></i>
                  <span>{{GetWordText('address')}}:</span>
                  <p class="mb-0">{{GetWordText('address_text')}}</p>
                </div>
              </div>
              <div class="col-md-4 mb-5 mb-md-0">
                <div class="px-5 pb-5 text-center footer-media"> <i class="flaticon-phone-call"></i>
                  <span>{{GetWordText('phone')}}:</span>
                  <a href="tel:+{{GetWordText('phone_text')}}">+{{GetWordText('phone_text')}}</a>
                </div>
              </div>
              <div class="col-md-4">
                <div class="px-5 pb-5 text-center footer-media border-0"> <i class="flaticon-message"></i>
                  <span>{{GetWordText('email')}}:</span>
                  <a href="mailto:{{GetWordText('email_text')}}">{{GetWordText('email_text')}}</a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="primary-footer">
    <div class="container">
      <div class="row">
        <div class="col-lg-3 col-md-6">
          <h5>{{GetWordText('about')}} <span class="text-theme"> {{GetWordText('kayan')}}</span></h5>
          <p class="mb-3">
            {{ (about_content.length>200)? (about_content | slice:0:200)+'..':(about_content) }}

          </p> <a class="btn-simple" routerLink="/about-us-history"><span>{{GetWordText('read_more')}} <i class="ms-2 fas fa-long-arrow-alt-right"></i></span></a>
        </div>
        <div class="col-lg-3 col-md-6 mt-5 mt-md-0 widget">
          <h5>{{GetWordText('Our')}} <span class="text-theme">{{GetWordText('events')}}</span></h5>
          <div class="recent-post mb-0">
          <ul class="list-unstyled">
            <li *ngFor="let row of latest_events" class="mb-3">
              <div class="recent-post-thumb">
                <img class="img-fluid" src="{{mediaUrl+row['photo']}}" alt="">
              </div>
              <div class="recent-post-desc"> <a routerLink="/events/{{row['id']}}/{{urlFunc(row['title_ar'],row['title_en'])}}">{{ViewLang(row['title_ar'],row['title_en'])}}</a>
                <span>{{row['start_date']}} - {{row['end_date']}} </span>
              </div>
            </li>

          </ul>
          </div>
        </div>
        <div class="col-lg-3 col-md-6 mt-5 mt-lg-0 widget">
          <h5>{{GetWordText('latest')}} <span class="text-theme"> {{GetWordText('blogs')}}</span></h5>
          <div class="recent-post mb-0">
            <ul class="list-unstyled">
              <li *ngFor="let row of blogs" class="mb-3">
                <div class="recent-post-thumb">
                  <img class="img-fluid" src="{{mediaUrl+row['photo']}}" alt="">
                </div>
                <div class="recent-post-desc"> <a routerLink="/blog/{{row['id']}}/{{urlFunc(row['title_ar'],row['title_en'])}}">{{ViewLang(row['title_ar'],row['title_en'])}}</a>
                  <span>{{row['createdAt']}}</span>
                </div>
              </li>

            </ul>
          </div>
        </div>
        <div class="col-lg-3 col-md-6 mt-5 mt-lg-0">
          <h5>{{GetWordText('Working')}} <span class="text-theme"> {{GetWordText('hours')}}</span></h5>
          <p>{{GetWordText('working_hours_short_description')}}</p>
          <ul class="list-unstyled working-hours clearfix">
            <li><span>{{GetWordText('Working_days')}}</span> {{GetWordText('Working_time')}}</li>
            <li><span>{{GetWordText('closed_days')}}</span> {{GetWordText('closed')}}</li>
          </ul>
          <div class="social-icons social-colored mt-3">
            <ul class="list-inline mb-0">
              <li class="social-facebook"><a target="_blank" href="{{GetWordText('facebook_url')}}"><i class="fab fa-facebook-f" aria-hidden="true"></i></a>
              </li>
              <li class="social-twitter"><a target="_blank" href="{{GetWordText('twitter_url')}}"><i class="fab fa-twitter" aria-hidden="true"></i></a>
              </li>
              <li class="social-gplus"><a target="_blank" href="{{GetWordText('google_url')}}"><i class="fab fa-google-plus-g" aria-hidden="true"></i></a>
              </li>
              <li class="social-linkedin"><a target="_blank" href="{{GetWordText('linkedin_url')}}"><i class="fab fa-linkedin-in" aria-hidden="true"></i></a>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="secondary-footer">
    <div class="container" style="direction: ltr !important;">
      <div class="copyright">
        <div class="row align-items-center">
          <div class="col-md-7"> <span>Copyright 2023 KAYAN.ltd  | All Rights Reserved</span>
          </div>
          <div class="col-md-5 text-md-end mt-3 mt-md-0 footer-list">
            <ul class="list-inline">
              <li>
              </li>
              <li> Powered  by <a target="_blank" href="https://eg7tech.com"> seven Tech </a>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </div>
</footer>

<!--footer end-->


<!-- page wrapper end -->


<!-- get a quote start -->

<!--<a class="contact-btn">-->
<!--  <div class="contact-bg">Get a Quote</div>-->
<!--</a>-->
<!--<div class="contact-form text-black"> <a class="close-btn text-capitalize text-end"><i class="flaticon-cancel"></i></a>-->
<!--  <h2 class="title mb-5">Request A <span> Quote </span></h2>-->
<!--  <form id="queto-form" method="post" action="php/contact.php">-->
<!--    <div class="messages"></div>-->
<!--    <div class="form-group">-->
<!--      <input id="form_name1" type="text" name="name" class="form-control" placeholder="Your Name" required="required" data-error="Name is required.">-->
<!--      <div class="help-block with-errors"></div>-->
<!--    </div>-->
<!--    <div class="form-group">-->
<!--      <input id="form_email1" type="email" name="email" class="form-control" placeholder="Your Email" required="required" data-error="Valid email is required.">-->
<!--      <div class="help-block with-errors"></div>-->
<!--    </div>-->
<!--    <div class="form-group">-->
<!--      <input id="form_project1" type="text" name="name" class="form-control" placeholder="Project Name" required="required">-->
<!--    </div>-->
<!--    <div class="form-group">-->
<!--      <textarea id="form_message1" name="message" class="form-control" placeholder="Your Message" rows="3" required="required" data-error="Please,leave us a message."></textarea>-->
<!--      <div class="help-block with-errors"></div>-->
<!--    </div>-->
<!--    <button class="btn btn-border btn-radius"><span>Submit</span>-->
<!--    </button>-->
<!--  </form>-->
<!--</div>-->

<!-- get a quote end -->


<!--back-to-top start-->

<div class="scroll-top"><a class="smoothscroll" href="#top"><i class="fas fa-chevron-up"></i></a></div>

<!--back-to-top end-->
