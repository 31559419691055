<app-header></app-header>
<!--page title start-->
<app-page-route title="Our" span_title="Features"></app-page-route>


<!--featured box style1 start-->

<section>
  <div class="container">
    <div class="row">
      <div *ngFor="let row of dataList" class="col-lg-4 mt-5 col-md-6">
        <div class="featured-item text-center">
          <div class="featured-icon"> <i class="{{row['icon']}}"></i>
          </div>
          <div class="featured-title text-uppercase">
            <h5>{{ViewLang(row['name_ar'],row['name_en'])}}</h5>
          </div>
          <div class="featured-desc">
            <p class="mb-3"> {{ViewLang(row['content_ar'],row['content_en'])}} </p>
           </div>
        </div>
      </div>

    </div>
  </div>
</section>

<!--featured box style1 end-->

<app-footer></app-footer>
