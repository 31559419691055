<app-header></app-header>
<!--page title start-->
<app-page-route title="contact_us"></app-page-route>
<!--contact start-->

<section class="contact-2">
  <div class="container">
    <div class="row align-items-center g-0">
      <div class="col-lg-4">
        <div class="p-5 theme-bg text-white z-index-1">
          <h2 class="title">{{GetWordText('Get In')}} <span>{{GetWordText('Touch!')}}</span></h2>
          <p> {{GetWordText('contact_description')}}</p>
          <ul class="contact-info list-unstyled mt-4">
            <li class="mb-4"><i class="flaticon-paper-plane"></i><span>{{GetWordText('address')}}:</span>
              <p>{{GetWordText('address_text')}}</p>
            </li>
            <li class="mb-4"><i class="flaticon-phone-call"></i><span>{{GetWordText('phone')}}:</span><a href="tel:+{{GetWordText('phone_text')}}">+{{GetWordText('phone_text')}}</a>
            </li>
            <li><i class="flaticon-message"></i><span>{{GetWordText('email')}}</span><a href="mailto:{{GetWordText('email_text')}}">{{GetWordText('email_text')}} </a>
            </li>
          </ul>
        </div>
      </div>
      <div class="col-lg-8">
        <div class="contact-main white-bg p-5 ps-lg-11 ms-lg-n6 border-bottom-0">
          <h2 class="title mb-4">{{GetWordText('send')}} <span>{{GetWordText('message')}}</span></h2>
          <form id="contact-form" method="post">
            <div class="messages"></div>
            <div class="form-group">
              <input id="form_name" type="text" name="name" class="form-control" placeholder="{{GetWordText('full_name')}}" required="required"  >
              <div class="help-block with-errors"></div>
            </div>
            <div class="form-group">
              <input id="form_email" type="email" name="email" class="form-control" placeholder="{{GetWordText('email')}}" required="required"  >
              <div class="help-block with-errors"></div>
            </div>
            <div class="form-group">
              <input id="form_phone" type="tel" name="phone" class="form-control" placeholder="{{GetWordText('phone')}}" required="required"  >
              <div class="help-block with-errors"></div>
            </div>
            <div class="form-group">
              <textarea id="form_message" name="message" class="form-control" placeholder="{{GetWordText('Message')}}" rows="4" required="required" ></textarea>
              <div class="help-block with-errors"></div>
            </div>
            <button class="btn btn-border btn-radius"><span>{{GetWordText('send')}}</span>
            </button>
          </form>
        </div>
      </div>
    </div>
  </div>
</section>

<!--contact end-->

<app-footer></app-footer>
