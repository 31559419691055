import { Component, OnInit } from '@angular/core';
import {LangService} from "../../lang/lang.service";
import {HttpClient} from "@angular/common/http";
import {environment} from "../../../environments/environment";

@Component({
  selector: 'app-our-vision',
  templateUrl: './our-vision.component.html',
  styleUrls: ['./our-vision.component.css']
})
export class OurVisionComponent implements OnInit {

  page_data:any;
  mediaUrl:any;

  constructor(private  langService:LangService,private http:HttpClient) {
    this.LoadHomeAbout();
    this.mediaUrl=environment.mediaUrl;

  }
  public  GetWordText(key:any){
    return this.langService.GetWord(key);

  }
  getAbout(){
    return this.http.get<any>(environment.serverUrl+`home_about/`)
  }
  ngOnInit(): void {
  }

  urlFunc(arabic: string,english:string) {
    if(localStorage.getItem('lang')=='ar'){
      return  arabic.split(' ').join('-');
    }else if(localStorage.getItem('lang')=='en'){
      return  english.split(' ').join('-');
    }else {
      return  english.split(' ').join('-');
    }

  }

  public   ViewLang(arabic:string,english:string){
    if(localStorage.getItem('lang')=='ar'){
      return  arabic;
    }else if(localStorage.getItem('lang')=='en'){
      return  english;
    }else {
      return  english;
    }
  }


  LoadHomeAbout(){
    this.getAbout().subscribe(data=>{
      console.log(data.result);
      if(data.result){
        this.page_data=data.result;
      }

    },error => {
      console.log(error);
    });
  }
}
