<app-header></app-header>
<!--page title start-->
<app-page-route title="social" span_title="responsibility"></app-page-route>
<!--social-responsibility start-->

<section>
  <div class="container">
    <div class="row align-items-center">
      <div class="col-lg-6 col-md-12">
        <div class="owl-carousel no-pb" data-items="1" data-dots="false" data-nav="true">
          <div class="item">
            <img class="img-fluid w-100" src="{{mediaUrl+page_data[6]['photo1']}}" alt="">
          </div>
          <div class="item">
            <img class="img-fluid w-100" src="{{mediaUrl+page_data[6]['photo2']}}" alt="">
          </div>
          <div class="item">
            <img class="img-fluid w-100" src="{{mediaUrl+page_data[6]['photo3']}}" alt="">
          </div>
          <div class="item">
            <img class="img-fluid w-100" src="{{mediaUrl+page_data[6]['photo4']}}" alt="">
          </div>
        </div>
      </div>
      <div class="col-lg-6 col-md-12 mt-3 mt-lg-0">
        <h2 class="title"> {{ViewLang(page_data[6]['title_ar'],page_data[6]['title_en'])}}  </h2>
        <p>
          {{ViewLang(page_data[6]['content_ar'],page_data[6]['content_en'])}}
        </p>
      </div>
    </div>
  </div>
</section>

<!--social-responsibility end-->
<app-footer></app-footer>
