import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HeaderComponent } from './layout/header/header.component';
import { FooterComponent } from './layout/footer/footer.component';
import { HomeComponent } from './home/home.component';
import { SliderComponent } from './slider/slider.component';
import { ServicesComponent } from './services/services/services.component';
import { SocialResponsibilityComponent } from './about-us/social-resposability/social-responsibility.component';
import { HistoryComponent } from './about-us/history/history.component';
import { OurVisionComponent } from './about-us/our-vision/our-vision.component';
import { OurMissionComponent } from './about-us/our-mission/our-mission.component';
import { WhyChooseUsComponent } from './about-us/why-choose-us/why-choose-us.component';
import { ServicesPageComponent } from './services/services-page/services-page.component';
import { NewsListComponent } from './news/news-list/news-list.component';
import { NewsDetailsComponent } from './news/news-details/news-details.component';
import { ProjectsListComponent } from './projects/projects-list/projects-list.component';
import { ProjectDetailsComponent } from './projects/project-details/project-details.component';
import { EventsListComponent } from './events/events-list/events-list.component';
import { EventDetailsComponent } from './events/event-details/event-details.component';
import { FeaturesComponent } from './features/features.component';
import { BlogListComponent } from './blog/blog-list/blog-list.component';
import { BlogDetailsComponent } from './blog/blog-details/blog-details.component';
import { JobsListComponent } from './jobs/jobs-list/jobs-list.component';
import { JobDetailsComponent } from './jobs/job-details/job-details.component';
import { SupplierJoinComponent } from './supplier-join/supplier-join.component';
import { ContactUsComponent } from './contact-us/contact-us.component';
import { ClientsComponent } from './layout/clients/clients.component';
import { CallToActionComponent } from './layout/call-to-action/call-to-action.component';
import { PageRouteComponent } from './layout/page-route/page-route.component';
import {HttpClientModule} from "@angular/common/http";
import { ValuesComponent } from './about-us/values/values.component';
import {PageRouteTitleComponent} from "./layout/page-title-route/page-route-title.component";
import { SideBarComponent } from './layout/side-bar/side-bar.component';
import {NgOptimizedImage} from "@angular/common";

@NgModule({
  declarations: [
    AppComponent,
    HeaderComponent,
    FooterComponent,
    HomeComponent,
    SliderComponent,
    ServicesComponent,
    SocialResponsibilityComponent,
    HistoryComponent,
    OurVisionComponent,
    OurMissionComponent,
    WhyChooseUsComponent,
    ServicesPageComponent,
    NewsListComponent,
    NewsDetailsComponent,
    ProjectsListComponent,
    ProjectDetailsComponent,
    EventsListComponent,
    EventDetailsComponent,
    FeaturesComponent,
    BlogListComponent,
    BlogDetailsComponent,
    JobsListComponent,
    JobDetailsComponent,
    SupplierJoinComponent,
    ContactUsComponent,
    ClientsComponent,
    CallToActionComponent,
    PageRouteComponent,
    ValuesComponent,
    PageRouteTitleComponent,
    SideBarComponent
  ],
    imports: [
        BrowserModule,
        AppRoutingModule,
        HttpClientModule,
        NgOptimizedImage,
    ],
  providers: [],
  bootstrap: [AppComponent]
})
export class AppModule { }
