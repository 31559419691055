import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import {HomeComponent} from "./home/home.component";
import {HistoryComponent} from "./about-us/history/history.component";
import {OurVisionComponent} from "./about-us/our-vision/our-vision.component";
import {OurMissionComponent} from "./about-us/our-mission/our-mission.component";
import {WhyChooseUsComponent} from "./about-us/why-choose-us/why-choose-us.component";
import {ServicesComponent} from "./services/services/services.component";
import {ServicesPageComponent} from "./services/services-page/services-page.component";
import {FeaturesComponent} from "./features/features.component";
import {ProjectsListComponent} from "./projects/projects-list/projects-list.component";
import {ProjectDetailsComponent} from "./projects/project-details/project-details.component";
import {NewsListComponent} from "./news/news-list/news-list.component";
import {NewsDetailsComponent} from "./news/news-details/news-details.component";
import {EventsListComponent} from "./events/events-list/events-list.component";
import {EventDetailsComponent} from "./events/event-details/event-details.component";
import {BlogListComponent} from "./blog/blog-list/blog-list.component";
import {BlogDetailsComponent} from "./blog/blog-details/blog-details.component";
import {JobsListComponent} from "./jobs/jobs-list/jobs-list.component";
import {JobDetailsComponent} from "./jobs/job-details/job-details.component";
import {SupplierJoinComponent} from "./supplier-join/supplier-join.component";
import {ContactUsComponent} from "./contact-us/contact-us.component";
import {SocialResponsibilityComponent} from "./about-us/social-resposability/social-responsibility.component";
import {ValuesComponent} from "./about-us/values/values.component";

const routes: Routes = [
  {path:"",component:HomeComponent},
  {path:"home/:lang",component:HomeComponent},
  {path:"about-us-history",component:HistoryComponent},
  {path:"about-us-our-vision",component:OurVisionComponent},
  {path:"about-us-our-mission",component:OurMissionComponent},
  {path:"why-choose-us",component:WhyChooseUsComponent},
  {path:"social-responsibility",component:SocialResponsibilityComponent},
  {path:"values-goals",component:ValuesComponent},
  {path:"why-choose-us",component:WhyChooseUsComponent},
  {path:"supplier-join",component:SupplierJoinComponent},
  {path:"contact-us",component:ContactUsComponent},
  {path:"features",component:FeaturesComponent},
  {path:"services",component:ServicesComponent},
  {path:"services/:id/:service_name",component:ServicesPageComponent},
  {path:"our-projects",component:ProjectsListComponent},
  {path:"project/:id/:project_name",component:ProjectDetailsComponent},
  {path:"news",component:NewsListComponent},
  {path:"news/:id/:news_title",component:NewsDetailsComponent},
  {path:"events",component:EventsListComponent},
  {path:"events/:id/:event_title",component:EventDetailsComponent},
  {path:"blog",component:BlogListComponent},
  {path:"blog/:id/:post_title",component:BlogDetailsComponent},
  {path:"jobs",component:JobsListComponent},
  {path:"jobs/:id/:job_title",component:JobDetailsComponent},
  ];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
