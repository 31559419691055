import { Component, OnInit } from '@angular/core';
import {LangService} from "../lang/lang.service";
import {HttpClient} from "@angular/common/http";
import {environment} from "../../environments/environment";

@Component({
  selector: 'app-features',
  templateUrl: './features.component.html',
  styleUrls: ['./features.component.css']
})
export class FeaturesComponent implements OnInit {

  dataList:any;

  mediaUrl:any;
  constructor(private  langService:LangService,private http:HttpClient) {
    this.LoadData();
    this.mediaUrl=environment.mediaUrl;
  }


  public  GetWordText(key:any){
    return this.langService.GetWord(key);

  }

  ngOnInit(): void {
  }

  urlFunc(arabic: string,english:string) {
    if(localStorage.getItem('lang')=='ar'){
      return  arabic.split(' ').join('-');
    }else if(localStorage.getItem('lang')=='en'){
      return  english.split(' ').join('-');
    }else {
      return  english.split(' ').join('-');
    }

  }

  public   ViewLang(arabic:string,english:string){
    if(localStorage.getItem('lang')=='ar'){
      return  arabic;
    }else if(localStorage.getItem('lang')=='en'){
      return  english;
    }else {
      return  english;
    }
  }
  getData(){
    return this.http.get<any>(environment.serverUrl+`features/`)
  }
  LoadData(){
    this.getData().subscribe(data=>{
      console.log(data.result);
      this.dataList=data.result;

    },error => {
      console.log(error);
    });
  }


}
