
  <div class="widget">
    <h5 class="widget-title">{{GetWordText('events')}}</h5>
    <div class="recent-post">
      <ul class="list-unstyled">
        <li *ngFor="let row of latest_events" class="mb-3">
          <div class="recent-post-thumb">
            <img class="img-fluid" src="{{mediaUrl+row['photo']}}" alt="">
          </div>
          <div class="recent-post-desc">
                <a routerLink="/blog/{{row['id']}}/{{urlFunc(row['title_ar'],row['title_en'])}}">{{ViewLang(row['title_ar'],row['title_en'])}}</a>
                <span><i class="fas fa-calendar-alt text-theme me-1"></i> {{row['start_date']}} - {{row['end_date']}}</span>
          </div>
        </li>

      </ul>
    </div>
  </div>

  <div class="widget">
    <h5 class="widget-title">{{GetWordText('latest_blog')}}</h5>
    <div class="recent-post">
      <ul class="list-unstyled">
        <li *ngFor="let row of blogs" class="mb-3">
          <div class="recent-post-thumb">
            <img class="img-fluid" src="{{mediaUrl+row['photo']}}" alt="">
          </div>
          <div class="recent-post-desc">
         <a routerLink="/events/{{row['id']}}/{{urlFunc(row['title_ar'],row['title_en'])}}">{{ViewLang(row['title_ar'],row['title_en'])}}</a>


            <span><i class="fas fa-calendar-alt text-theme me-1"></i> {{row['createdAt']}}</span>
          </div>
        </li>

      </ul>
    </div>
  </div>


  <div class="widget">
    <h5 class="widget-title">{{GetWordText('latest_news')}}</h5>
    <div class="recent-post">
      <ul class="list-unstyled">
        <li *ngFor="let row of newslist" class="mb-3">
          <div class="recent-post-thumb">
            <img class="img-fluid" src="{{mediaUrl+row['photo']}}" alt="">
          </div>
          <div class="recent-post-desc">
         <a routerLink="/news/{{row['id']}}/{{urlFunc(row['title_ar'],row['title_en'])}}">{{ViewLang(row['title_ar'],row['title_en'])}}</a>


            <span><i class="fas fa-calendar-alt text-theme me-1"></i> {{row['createdAt']}}</span>
          </div>
        </li>

      </ul>
    </div>
  </div>


