import { Component, OnInit } from '@angular/core';
import {LangService} from "../../lang/lang.service";
import {HttpClient} from "@angular/common/http";
import {environment} from "../../../environments/environment";
declare function  loadScripts():any;

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.css']
})
export class FooterComponent implements OnInit {
  services:any;
  about_content:any;
  blogs:any;
  competencies:any;
  latest_events:any;
  mediaUrl:any;

  constructor(private  langService:LangService,private http:HttpClient) {
    this.LoadHomeAbout();
    this.LoadHomeServices();
    this.LoadCompetencies();
    this.LoadBlogContent();
    this.LoadLatestContent();
    this.mediaUrl=environment.mediaUrl;
  }
  ngOnInit(): void {
    loadScripts();

  }
  public  GetWordText(key:any){
    return this.langService.GetWord(key);

  }

  getHomeAbout(){
    return this.http.get<any>(environment.serverUrl+`home_about/`)
  }
  getServices(){
    return this.http.get<any>(environment.serverUrl+`services/`)
  }
  getCompetencies(){
    return this.http.get<any>(environment.serverUrl+`competencies/`)
  }

  LoadHomeServices(){
    this.getServices().subscribe(data=>{
      console.log(data.result);
      this.services=data.result;

    },error => {
      console.log(error);
    });
  }

  LoadHomeAbout(){
    this.getHomeAbout().subscribe(data=>{
      console.log(data.result);
      if(data.result){

         this.about_content=this.langService.viewLang(data.result[0]['content_ar'],data.result[0]['content_en'])

      }

    },error => {
      console.log(error);
    });
  }
  LoadCompetencies(){
    this.getCompetencies().subscribe(data=>{
      console.log(data.result);
      if(data.result){

        this.competencies=data.result;

      }

    },error => {
      console.log(error);
    });
  }
  public   ViewLang(arabic:string,english:string){
    if(localStorage.getItem('lang')=='ar'){
      return  arabic;
    }else if(localStorage.getItem('lang')=='en'){
      return  english;
    }else {
      return  english;
    }
  }
  urlFunc(arabic: string,english:string) {
    if(localStorage.getItem('lang')=='ar'){
      return  arabic.split(' ').join('-');
    }else if(localStorage.getItem('lang')=='en'){
      return  english.split(' ').join('-');
    }else {
      return  english.split(' ').join('-');
    }

  }


  getBlogContent(){
    return this.http.get<any>(environment.serverUrl+`latestBlog/`)
  }
  getLatestContent(){
    return this.http.get<any>(environment.serverUrl+`latestEvents/`)
  }

  LoadBlogContent(){
    this.getBlogContent().subscribe(data=>{
      console.log(data.result);
      if(data.result){
        this.blogs=data.result;
      }

    },error => {
      console.log(error);
    });
  }
  LoadLatestContent(){
    this.getLatestContent().subscribe(data=>{
      console.log(data.result);
      if(data.result){
        this.latest_events=data.result;
      }

    },error => {
      console.log(error);
    });
  }

  protected readonly matchMedia = matchMedia;
}
