<!--page title start-->


 <section class="page-title overflow-hidden text-center parallaxie" data-overlay="7" data-bg-img="assets/images/bg/3.jpeg">
  <div class="container">
    <div class="row">
      <div class="col-lg-8 col-md-12 mx-auto">
        <h1 class="title mb-0"> {{title}}  <span *ngIf="span_title">{{span_title}} </span></h1>
      </div>
    </div>
    <nav aria-label="breadcrumb" class="page-breadcrumb">
      <ol class="breadcrumb">
        <li class="breadcrumb-item"><a routerLink="/"><i class="fas fa-home"></i></a>
        </li>
        <li class="breadcrumb-item active" aria-current="page"> {{title}} <span *ngIf="span_title">{{span_title}} </span> </li>
      </ol>
    </nav>
  </div>
</section>

<!--page title end-->
