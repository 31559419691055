import { Injectable } from '@angular/core';
import {HttpClient,HttpHeaders} from "@angular/common/http";
import {Language} from "./language";

@Injectable({
  providedIn: 'root'
})
export class LangService {
  wordList:any;

  constructor(private language:Language) { }

  GetWord(key: string) {

    const index = this.language.lang_words.map(object => object.text_key).indexOf(key);
     if (index > 0) {
      return this.languageLoad(this.language.lang_words[index]['text_ar'], this.language.lang_words[index]['text_en']);
    } else {
      return '{'+key+'}';
    }

  }


  private languageLoad(arabic: any, english: any) {
    if(localStorage.getItem('lang')=='ar'){
      return arabic;
    }else if(localStorage.getItem('lang')=='en'){
      return english;
    }else if(localStorage.getItem('lang')=='AR'){
      return arabic;
    }else if(localStorage.getItem('lang')=='EN'){
      return english;
    }else {
      return english;
    }
  }

  ChangeLang(lang:any){
    return localStorage.setItem('lang',lang);
  }


    ArabicCheck(userInput:any) {
    let regex = /[\u0600-\u06FF\u0750-\u077F]/;
    if(!userInput.match(regex)) { // Check if the regular expression matches
      return true;
    } else {
      return false;
    }
  }


  viewLang(arabic:string, english:string) {
    if(localStorage.getItem('lang')=='ar'){
      return arabic;
    }else if(localStorage.getItem('lang')=='en'){
      return english;
    }else {
      return english;
    }
  }
}
//let englishInput = "hello";
// let arabicInput = "مرحبا";
// function myFunction(userInput) {
//   let regex = /[\u0600-\u06FF\u0750-\u077F]/;
//   if(!userInput.match(regex)) { // Check if the regular expression matches
//     alert("Only use english characters!");
//   } else {
//     alert("Arabic letters used!"); // Added this line just for showcasing
//   }
// }
//
// myFunction(englishInput); // Will alert that english letters used
// myFunction(arabicInput); // Will alert that arabic letters used
