import {Injectable} from '@angular/core';
import {HttpClient} from "@angular/common/http";
import {environment} from "../../environments/environment";

@Injectable({
  providedIn: 'root'
})
export class Language {

  lang_words: Array<any>;

  constructor(private  http:HttpClient) {
  this.LoadApp_textList();

    this.lang_words = [];
//
  }
  GetApp_textList(){
    return this.http.get<any>(environment.serverUrl+`app_text/`)
  }
  LoadApp_textList(){
    this.GetApp_textList().subscribe(data=>{
      console.log(data.result);
      if(data.result){

        this.lang_words=data.result;

      }

    },error => {
      console.log(error);
    });
  }


}

