<app-header></app-header>
<!--page title start-->
<app-page-route   title="Our" span_title="services"></app-page-route>


<!--services start-->

<section class="feuture-main text-center">
  <div class="container">
    <div class="row">
      <div class="col-lg-8 col-md-10 mx-auto">
        <div class="section-title">
          <h2 class="title">{{GetWordText('Our')}} <span> {{GetWordText('services')}}</span></h2>
          <p class="mb-0 text-white"> {{GetWordText('services_description')}} </p>
        </div>
      </div>
    </div>
    <div class="row">

      <div *ngFor="let row of servicesList"  class="col-lg-4 col-md-6">
        <div class="featured-item text-center">
          <div class="featured-icon"> <i class="{{row['icon']}}"></i>
          </div>
          <div class="featured-title text-uppercase">
            <h5>{{ViewLang(row['name_ar'],row['name_en'])}}</h5>
          </div>
          <div class="featured-desc">
            <p class="mb-3">
              {{ (ViewLang(row['content_ar'],row['content_en']).length>100)? (ViewLang(row['content_ar'],row['content_en']) | slice:0:100)+'..':(ViewLang(row['content_ar'],row['content_en'])) }}
            </p> <a routerLink="/services/{{row['id']}}/{{urlFunc(row['name_ar'],row['name_en'])}}">{{GetWordText('more_details')}} <i class="fas fa-arrow-right"></i></a>
          </div>
        </div>
      </div>

      <!--        <div *ngFor="let row of services"  class="col-lg-4 col-md-6 mb-4" >-->
      <!--          <div class="service-box">-->
      <!--            <div class="service-flip theme-bg" >-->
      <!--              <div class="service-flip-images service-flip-front" style="{{'background-image: url('+mediaUrl+row['photo']+')'}}" >-->
      <!--                <div class="service-flip-title">-->
      <!--                  <h5>{{ViewLang(row['name_ar'],row['name_en'])}}</h5>-->
      <!--                </div>-->
      <!--                <div class="flip-icon"> <i class="{{row['icon']}}"></i>-->
      <!--                </div>-->
      <!--              </div>-->
      <!--              <div class="service-flip-desc service-flip-back">-->
      <!--                <div class="service-flip-text"> <i class="{{row['icon']}}"></i>-->
      <!--                  <p>-->
      <!--                    {{ (ViewLang(row['content_ar'],row['content_en']).length>100)? (ViewLang(row['content_ar'],row['content_en']) | slice:0:100)+'..':(ViewLang(row['content_ar'],row['content_en'])) }}-->
      <!--                  </p>-->
      <!--                  <a class="btn btn-info btn-border" >{{GetWordText('more_details')}} <i class="fas fa-arrow-right"></i></a>-->

      <!--                </div>-->
      <!--              </div>-->
      <!--            </div>-->
      <!--          </div>-->
      <!--        </div>-->

    </div>


  </div>
</section>

<!--services end-->
<app-footer></app-footer>
