<app-header></app-header>
<!--page title start-->
<app-page-title-route title="{{ViewLang(eventData[0]['job_title_ar'],eventData[0]['job_title_en'])}}" span_title=""></app-page-title-route>

<!--service details start-->

<!--blog start-->

<section>
  <div class="container">
    <div class="row">
      <div class="col-lg-9 col-md-12">
        <div class="blog-classic">
          <div class="post">
            <div class="post-image">
              <img src="{{mediaUrl+eventData[0]['photo']}}" alt="">
              <div class="post-date">{{GetWordText('date')}} : {{eventData[0]['createdAt']}}  </div>
            </div>
            <div class="post-desc">
              <div class="post-title">
                <h5>{{ViewLang(eventData[0]['job_title_ar'],eventData[0]['job_title_en'])}}</h5>
              </div>
              <p>  {{ViewLang(eventData[0]['content_ar'],eventData[0]['content_en'])}} </p>
            </div>
          </div>
        </div>

      </div>
      <div class="col-lg-3 col-md-12 sidebar">
        <app-side-bar></app-side-bar>
      </div>
    </div>
  </div>
</section>

<!--blog end-->
<app-footer></app-footer>
