import { Component, OnInit } from '@angular/core';
import {HttpClient} from "@angular/common/http";
import {environment} from "../../environments/environment";
import {LangService} from "../lang/lang.service";
declare  function  databgcolor():any;

@Component({
  selector: 'app-slider',
  templateUrl: './slider.component.html',
  styleUrls: ['./slider.component.css']
})
export class SliderComponent implements OnInit {

  services:any;
  mediaUrl:any;
  photo1:any="2.jpeg";
  photo2:any="3.jpeg";
  photo3:any="4.jpeg";
  constructor(private  langService:LangService,private http:HttpClient) {
     this.LoadHomeServices();
    this.mediaUrl=environment.mediaUrl;
  }

  ngOnInit(): void {

  }
  ngAfterViewInit(){
    databgcolor();

  }

  public  GetWordText(key:any){
    return this.langService.GetWord(key);

  }

  getServices(){
    return this.http.get<any>(environment.serverUrl+`slider/`)
  }

  LoadHomeServices(){
    this.getServices().subscribe(data=>{
      console.log(data.result);
      this.services=data.result;
      if(this.services.length>0){
        this.photo1=this.services[0]['photo'];
        this.photo2=this.services[1]['photo'];
        this.photo3=this.services[2]['photo'];
      }

    },error => {
      console.log(error);
    });
  }



  urlFunc(arabic: string,english:string) {
    if(localStorage.getItem('lang')=='ar'){
      return  arabic.split(' ').join('-');
    }else if(localStorage.getItem('lang')=='en'){
      return  english.split(' ').join('-');
    }else {
      return  english.split(' ').join('-');
    }

  }

  public   ViewLang(arabic:string,english:string){
    if(localStorage.getItem('lang')=='ar'){
      return  arabic;
    }else if(localStorage.getItem('lang')=='en'){
      return  english;
    }else {
      return  english;
    }
  }


}
