import {Component, Input, OnInit} from '@angular/core';
import {LangService} from "../../lang/lang.service";
declare function  databgcolor():any;

@Component({
  selector: 'app-page-route',
  templateUrl: './page-route.component.html',
  styleUrls: ['./page-route.component.css']
})
export class PageRouteComponent implements OnInit {
  @Input() title: any;
  @Input() span_title: any;
  @Input() bgImage: any;
  topBg:any;
  constructor(private  langService:LangService) { }

  ngOnInit(): void {
    if(this.bgImage==null){
      this.topBg="assets/images/bg/2.jpeg";
    }
    databgcolor();
  }
  ngAfterViewInit(){
    databgcolor();

  }

  public  GetWordText(key:any){
    return this.langService.GetWord(key);

  }
}
