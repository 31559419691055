import { Component } from '@angular/core';
import {LangService} from "../../lang/lang.service";
import {HttpClient} from "@angular/common/http";
import {environment} from "../../../environments/environment";

@Component({
  selector: 'app-side-bar',
  templateUrl: './side-bar.component.html',
  styleUrls: ['./side-bar.component.css']
})
export class SideBarComponent {
  about_title:any;
  about_content:any;
  photo_1:any="assets/images/about/01.jpg";
  photo_2:any="assets/images/about/02.jpg";
  photo_3:any="assets/images/about/03.jpg";
  photo_4:any="assets/images/about/04.jpg";
  services:any;
  competencies:any;
  competencies_for:any;
  home_counter:any;
  suppliers_logos:any;
  home_content:any;
  latest_events:any;
  blogs:any;
  newslist:any;
  mediaUrl:any;
  constructor(private  langService:LangService,private http:HttpClient) {

    this.LoadHomeAbout();
    this.LoadHomeServices();
    this.LoadCompetencies();
    this.LoadHomeCounter();
    this.LoadSupplierLogos();
    this.LoadBlogContent();
    this.LoadEvents();
    this.LoadNews();
    this.mediaUrl=environment.mediaUrl;
  }

  ngOnInit(): void {
   }
  public  GetWordText(key:any){
    return this.langService.GetWord(key);

  }


  getHomeAbout(){
    return this.http.get<any>(environment.serverUrl+`home_about/`)
  } getEvents(){
    return this.http.get<any>(environment.serverUrl+`events/`)
  }
  getServices(){
    return this.http.get<any>(environment.serverUrl+`services/`)
  }
  getCompetencies(){
    return this.http.get<any>(environment.serverUrl+`competencies/`)
  }

  getHomeCounter(){
    return this.http.get<any>(environment.serverUrl+`home_counter/`)
  }
  getSuppliersLogos(){
    return this.http.get<any>(environment.serverUrl+`suppliers_logos/`)
  }
  getHomeContent(){
    return this.http.get<any>(environment.serverUrl+`home_content/`)
  }
  getBlogContent(){
    return this.http.get<any>(environment.serverUrl+`latestBlog/`)
  }  getNewsContent(){
    return this.http.get<any>(environment.serverUrl+`news/`)
  }
  LoadHomeServices(){
    this.getServices().subscribe(data=>{
      console.log(data.result);
      this.services=data.result;

    },error => {
      console.log(error);
    });
  }

  LoadHomeAbout(){
    this.getHomeAbout().subscribe(data=>{
      console.log(data.result);
      if(data.result){

        this.about_title=this.langService.viewLang(data.result[0]['title_ar'],data.result[0]['title_en'])
        this.about_content=this.langService.viewLang(data.result[0]['content_ar'],data.result[0]['content_en'])
        if(data.result[0]['photo_1']!=null){this.photo_1=data.result[0]['photo_1']}
        if(data.result[0]['photo_2']!=null){this.photo_2=data.result[0]['photo_2']}
        if(data.result[0]['photo_3']!=null){this.photo_3=data.result[0]['photo_3']}
        if(data.result[0]['photo_4']!=null){this.photo_4=data.result[0]['photo_4']}
      }

    },error => {
      console.log(error);
    });
  }
  LoadEvents(){
    this.getEvents().subscribe(data=>{
      console.log(data.result);
      this.latest_events=data.result;

    },error => {
      console.log(error);
    });
  }
  LoadNews(){
    this.getEvents().subscribe(data=>{
      console.log(data.result);
      this.newslist=data.result;

    },error => {
      console.log(error);
    });
  }
  LoadCompetencies(){
    this.getCompetencies().subscribe(data=>{
      console.log(data.result);
      if(data.result){

        this.competencies=data.result;
        this.competencies_for = this.competencies.splice(1);

      }

    },error => {
      console.log(error);
    });
  }
  LoadHomeCounter(){
    this.getHomeCounter().subscribe(data=>{
      console.log(data.result);
      if(data.result){
        this.home_counter=data.result;
      }

    },error => {
      console.log(error);
    });
  }

  LoadSupplierLogos(){
    this.getSuppliersLogos().subscribe(data=>{
      console.log(data.result);
      if(data.result){
        this.suppliers_logos=data.result;
      }

    },error => {
      console.log(error);
    });
  }
  LoadHomeContent(){
    this.getHomeContent().subscribe(data=>{
      console.log(data.result);
      if(data.result){
        this.home_content=data.result;
      }

    },error => {
      console.log(error);
    });
  }

  LoadBlogContent(){
    this.getBlogContent().subscribe(data=>{
      console.log(data.result);
      if(data.result){
        this.blogs=data.result;
      }

    },error => {
      console.log(error);
    });
  }

  urlFunc(arabic: string,english:string) {
    if(localStorage.getItem('lang')=='ar'){
      return  arabic.split(' ').join('-');
    }else if(localStorage.getItem('lang')=='en'){
      return  english.split(' ').join('-');
    }else {
      return  english.split(' ').join('-');
    }

  }

  public   ViewLang(arabic:string,english:string){
    if(localStorage.getItem('lang')=='ar'){
      return  arabic;
    }else if(localStorage.getItem('lang')=='en'){
      return  english;
    }else {
      return  english;
    }
  }

  public homeContentText(id:any){
    if(localStorage.getItem('lang')=='ar'){
      return this.home_content[id]['content_ar'];
    }else if(localStorage.getItem('lang')=='en'){
      return this.home_content[id]['content_en'];
    }else {
      return "Content Not Available!";
    }


  }

 }
